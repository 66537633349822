import { Scrollbars } from 'rc-scrollbars';
import { IconChecked } from '../../../assets/icons';
import type { SelectOption } from '../types';
import clsx from 'clsx';
import { isSelected } from '../utils';

type SelectDialogContentProps<OptionValue extends string | number> = {
  options: SelectOption<OptionValue>[];
  selectedOptions?: SelectOption<OptionValue> | SelectOption<OptionValue>[];
  handleSelect: (selectedOption: SelectOption<OptionValue>) => void;
  isDivided: boolean;
  variant: 'single-select' | 'multi-select';
  contentClassName?: string;
};

export const SelectDialogContent = <OptionValue extends string | number>({
  options,
  selectedOptions,
  handleSelect,
  isDivided,
  contentClassName,
}: SelectDialogContentProps<OptionValue>) => {
  return (
    <div
      className={clsx(
        'w-auto bg-white border border-grey-5 rounded-2.5 outline-none overflow-hidden shadow-1',
        contentClassName,
      )}
    >
      <Scrollbars autoHeight autoHeightMax={320}>
        <ul className={clsx({ 'divide-y divide-grey-4': isDivided })}>
          {options.map((option) => (
            <li
              key={option.value}
              className="flex flex-col gap-1 py-2 px-4 text-grey-1 hover:bg-grey-7 cursor-pointer transition duration-300"
              onClick={() => handleSelect(option)}
            >
              <div className="flex justify-between gap-2">
                <span className="text-grey-1 mt-1 text-body-md overflow-hidden text-ellipsis whitespace-nowrap">
                  {option.label}
                </span>
                {isSelected(option.value, selectedOptions) && <IconChecked />}
              </div>
              {option.description && (
                <span className="text-grey-3 mb-1 text-sm overflow-hidden text-ellipsis whitespace-nowrap">
                  {option.description}
                </span>
              )}
            </li>
          ))}
        </ul>
      </Scrollbars>
    </div>
  );
};
