import { useEffect, useMemo, useState } from 'react';
import {
  DiscountResponse,
  DiscountVariants,
  Product,
  RetailerCheckDiscountForMultipleProductsRequestBody,
} from '../../redux/api/retailerOrderListApi/types';
import { getPriceValue } from '../../utils/getPriceValue';
import { useRetailerCheckDiscountForMultipleProductsQuery } from '../../redux/api/retailerOrderListApi/retailerOrderListApi';

type Args = {
  product: Product;
  productsDiscounts: Record<string, DiscountResponse>;
  discountType: 'byUnit' | 'byCase';
};

export const useDiscountVariants = ({ productsDiscounts, product, discountType }: Args) => {
  const [discountVariants, setDiscountVariants] = useState<DiscountVariants[]>([]);
  const discountsKey = discountType === 'byCase' ? 'discountOnCase' : 'discountOnUnits';
  const discountsToCheck = useMemo<RetailerCheckDiscountForMultipleProductsRequestBody>(() => {
    const currentProductDiscounts = productsDiscounts[product.productId];
    const currentDiscountTypeValues = currentProductDiscounts[discountsKey];
    return currentDiscountTypeValues.slice(0, 3).map((item) => {
      return {
        sku: product.sku,
        quantity: discountType === 'byCase' ? product.unitsPerCase * item : item,
      };
    });
  }, [product, productsDiscounts, discountType, discountsKey]);

  const { data, isLoading } = useRetailerCheckDiscountForMultipleProductsQuery(discountsToCheck, {
    skip: !discountsToCheck.length,
  });

  useEffect(() => {
    const defaultVariantValues = {
      unitsPerCase: product.unitsPerCase,
      grossUnitPrice: product.unitPrice,
      grossCasePrice: product.unitPrice * product.unitsPerCase,
    };

    if (data) {
      setDiscountVariants(
        data.map((item, index) => {
          return {
            ...defaultVariantValues,
            quantity: productsDiscounts[product.productId][discountsKey][index],
            discountedUnitPrice: getPriceValue(item.discountedUnitPrice),
            discountedCasePrice: getPriceValue(item.discountedUnitPrice) * product.unitsPerCase,
          };
        }),
      );
    } else {
      const currentProductDiscounts = productsDiscounts[product.productId];
      const currentDiscountTypeValues = currentProductDiscounts[discountsKey];
      setDiscountVariants(
        currentDiscountTypeValues.slice(0, 3).map((_item, index) => {
          return {
            ...defaultVariantValues,
            quantity: productsDiscounts[product.productId][discountsKey][index],
            discountedUnitPrice: 0,
            discountedCasePrice: 0,
          };
        }),
      );
    }
  }, [data]);

  return { discountVariants, isLoading };
};
