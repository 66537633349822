import { lazy, Suspense, FC, LazyExoticComponent } from 'react';
import { useRoutes } from 'react-router-dom';
import { LoadingScreen } from '../components';
import SessionExpired from '../pages/SessionExpired';
import NewPasswordSetup from '../pages/newPasswordSetup/NewPasswordSetup';
import Login from '../pages/authentication/Login';
import { SidebarProps } from '../types';

const Loadable = (Component: LazyExoticComponent<FC<SidebarProps>>) => (props: SidebarProps) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

const UnAuthAppRoutes: FC = () => {
  return useRoutes([
    {
      path: '/',
      element: <Login />,
    },

    {
      path: '/sessionexpired',
      element: <SessionExpired />,
    },
    {
      path: '/passwordsetup',
      element: <NewPasswordSetup />,
    },
    {
      path: '/distributor-onboard',
      element: <DistributorOnboarding />,
    },
    {
      path: '/retailer-onboard',
      element: <RetailerOnboarding />,
    },
    {
      path: '*',
      element: <Login />,
    },
  ]);
};

const DistributorOnboarding = Loadable(
  lazy(() => import('../pages/distributers/DistributorOnboarding')),
);

const RetailerOnboarding = Loadable(lazy(() => import('../pages/retailer/RetailerOnboarding')));

export default UnAuthAppRoutes;
