import { useRef, useState } from 'react';
import clsx from 'clsx';
import type { MultiSelectProps, SingleSelectProps } from './types';
import { Popover } from 'react-aria-components';
import { SelectDialogContent, SelectButtonContent } from './components';
import { isSelectable } from './utils';

export const Select = <OptionValue extends string | number>({
  label,
  placeholder,
  options,
  selected,
  onChange,
  icon,
  isDisabled = false,
  variant,
  contentClassName,
  buttonClassName,
  isLastSelectable = true,
}: SingleSelectProps<OptionValue> | MultiSelectProps<OptionValue>) => {
  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef(null);
  const isDivided = !!options.find((option) => option.description);

  const handleOpen = () => {
    if (!isDisabled) {
      setIsOpen(true);
    }
  };

  const getSelectedOptions = () => {
    if (selected && Array.isArray(selected)) {
      return options.filter((option) => selected.includes(option.value));
    }

    return options.find((option) => option.value === selected);
  };

  return (
    <div className="flex flex-col gap-2">
      {label && (
        <span
          className={clsx('text-body-md font-headline', {
            'text-grey-4': isDisabled,
            'text-grey-1': !isDisabled,
          })}
        >
          {label}
        </span>
      )}
      <div
        role="button"
        className={clsx(
          'bg-white flex gap-1 items-center outline-none transition-all duration-300 border py-2.5 px-3 rounded-xl h-10',
          {
            'border-grey-5 hover:border-grey-4 cursor-pointer': !isOpen && !isDisabled,
            'border-grey-4 cursor-pointer': isOpen && !isDisabled,
            'text-grey-4 border-grey-6 !cursor-not-allowed': isDisabled,
          },
          buttonClassName,
        )}
        onClick={handleOpen}
        ref={triggerRef}
      >
        <SelectButtonContent
          placeholder={placeholder}
          selectedOptions={getSelectedOptions()}
          options={options}
          isOpen={isOpen}
          icon={icon}
          isDisabled={isDisabled}
          variant={variant}
        />
      </div>
      <Popover
        placement="bottom right"
        offset={8}
        triggerRef={triggerRef}
        isOpen={isOpen}
        onOpenChange={setIsOpen}
      >
        <SelectDialogContent
          options={options}
          selectedOptions={getSelectedOptions()}
          handleSelect={(option) => {
            if (isSelectable(option, variant, isLastSelectable, selected)) {
              onChange(option);
              setIsOpen(false);
            }
          }}
          isDivided={isDivided}
          variant={variant}
          contentClassName={contentClassName}
        />
      </Popover>
    </div>
  );
};
