import { Select } from '../Select';
import { IconLocation } from '../../assets/icons';
import type { ShopLocation } from '../../redux/api/retailerOrderListApi/types';

type LocationSelectProps = {
  isDisabled?: boolean;
  locations: ShopLocation[];
  selectedLocation?: ShopLocation;
  updateSelectedLocation: (option: string) => void;
};

export const LocationSelect = ({
  isDisabled,
  locations,
  selectedLocation,
  updateSelectedLocation,
}: LocationSelectProps) => {
  if (process.env.REACT_APP_HIDE_LOCATIONS === 'yes') {
    return null;
  }

  return (
    <Select
      isDisabled={!locations.length || isDisabled}
      placeholder="Select Location"
      icon={<IconLocation className="mr-2 min-w-6" />}
      options={locations?.map((item: ShopLocation) => ({
        value: item.id,
        label: item.name,
        description: item.address,
      }))}
      selected={selectedLocation?.id}
      onChange={(option) => updateSelectedLocation(option.value)}
      contentClassName="md:!w-80"
      buttonClassName="w-full"
      variant="single-select"
    />
  );
};
