import { Select } from '../../../components';
import { useEffect, useState } from 'react';
import { SelectOption } from '../../../components/Select/types';
import { useGetTopSoldProductsByDurationQuery } from '../../../redux/api/dashboardApi/dashboardApi';
import { Period } from '../../../redux/api/dashboardApi/types';
import { ProductRow } from './ProductRow';
import Skeleton from 'react-loading-skeleton';
import { useDiscounts } from '../../../hooks/useDiscounts';
import { useCart } from '../../../layout/cart/useCart';
import { durationFilterOptions, durationFilterOptionsDictionary } from '../constants';

export const RetailerTopSoldProducts = () => {
  const [selectedRange, setSelectedRange] = useState<SelectOption<Period>>(
    durationFilterOptionsDictionary.month,
  );

  const { subscribeToCartProductsQuantityUpdate } = useCart();
  const { data, isLoading, isFetching } = useGetTopSoldProductsByDurationQuery({
    period: selectedRange.value,
  });

  const {
    isAllDiscountsLoading,
    initialDiscounts,
    updatedQuantityDiscountsLoading,
    handleQuantityChange,
  } = useDiscounts(data);

  useEffect(() => {
    const subscription = subscribeToCartProductsQuantityUpdate(({ updatedData }) => {
      handleQuantityChange(updatedData);
    });

    return subscription.unsubscribe;
  }, []);

  const renderPageContent = () => {
    if (isLoading || isFetching) {
      return (
        <div className="flex flex-col gap-3">
          {new Array(3).fill(0).map((_item, index) => (
            <div className="w-full h-[calc(91px-24px/3)]" key={index}>
              <Skeleton className="w-full h-full" />
            </div>
          ))}
        </div>
      );
    }
    if (!data?.length) {
      return (
        <div className="py-2 flex justify-center items-center min-h-[calc(88px*3)]">
          No results found
        </div>
      );
    }
    return data?.map((product) => (
      <ProductRow
        isLoading={isAllDiscountsLoading || updatedQuantityDiscountsLoading[product.productId]}
        initialDiscounts={initialDiscounts}
        key={product.productId}
        product={product}
      />
    ));
  };

  return (
    <div className="bg-white rounded-2.5 w-full mt-4 p-6">
      <div className="flex justify-between items-center pb-4">
        <div className="text-subtitle-md text-grey-1 font-subtitle py-1.5">Top Sold Products</div>
        <div className="w-[100px]">
          <Select
            options={durationFilterOptions}
            selected={selectedRange?.value}
            onChange={setSelectedRange}
            buttonClassName="w-full"
            variant="single-select"
          />
        </div>
      </div>
      {renderPageContent()}
    </div>
  );
};
