import type { SelectOption } from '../types';

export const isSelected = <OptionValue extends string | number>(
  value: string | number,
  selectedOptions?: SelectOption<OptionValue> | SelectOption<OptionValue>[],
) => {
  if (selectedOptions) {
    if (Array.isArray(selectedOptions)) {
      if (selectedOptions.length) {
        return selectedOptions.find((option) => option.value === value);
      }
      return false;
    } else {
      return selectedOptions.value === value;
    }
  }

  return false;
};
