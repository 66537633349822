import { Select } from '../../../components';
import { useEffect, useState } from 'react';
import { SelectOption } from '../../../components/Select/types';
import { useGetPreviouslyOrderedProductsQuery } from '../../../redux/api/dashboardApi/dashboardApi';
import { Period } from '../../../redux/api/dashboardApi/types';
import { ProductRow } from './ProductRow';
import Skeleton from 'react-loading-skeleton';
import { useDiscounts } from '../../../hooks/useDiscounts';
import { useCart } from '../../../layout/cart/useCart';
import { durationFilterOptions, durationFilterOptionsDictionary } from '../constants';

export const RetailerOrderedProducts = () => {
  const [selectedRange, setSelectedRange] = useState<SelectOption<Period>>(
    durationFilterOptionsDictionary.month,
  );

  const { subscribeToCartProductsQuantityUpdate } = useCart();
  const { data, isLoading, isFetching } = useGetPreviouslyOrderedProductsQuery({
    period: selectedRange.value,
  });

  const {
    isAllDiscountsLoading,
    initialDiscounts,
    updatedQuantityDiscountsLoading,
    handleQuantityChange,
  } = useDiscounts(data);

  useEffect(() => {
    const subscription = subscribeToCartProductsQuantityUpdate(({ updatedData }) => {
      handleQuantityChange(updatedData);
    });

    return subscription.unsubscribe;
  }, []);

  const renderPageContent = () => {
    if (isLoading || isFetching) {
      return (
        <div className="flex flex-col gap-3">
          {new Array(7).fill(0).map((_item, index) => (
            <div className="w-full h-[calc(131px-24px/3)]" key={index}>
              <Skeleton className="w-full h-full" />
            </div>
          ))}
        </div>
      );
    }
    if (!data?.length) {
      return (
        <div className="py-5 flex items-center justify-center h-[546px]">No results found</div>
      );
    }
    return data?.map((product) => (
      <ProductRow
        isLoading={isAllDiscountsLoading || updatedQuantityDiscountsLoading[product.productId]}
        initialDiscounts={initialDiscounts}
        key={product.productId}
        product={product}
        showSalesCount
      />
    ));
  };

  return (
    <div className="bg-white rounded-2.5 w-full p-6">
      <div className="flex justify-between items-center pb-4">
        <div className="text-subtitle-md text-grey-1 font-subtitle">
          Previously ordered products
        </div>
        <div className="w-[100px]">
          <Select
            options={durationFilterOptions}
            selected={selectedRange?.value}
            onChange={setSelectedRange}
            buttonClassName="w-full"
            variant="single-select"
          />
        </div>
      </div>
      {renderPageContent()}
    </div>
  );
};
