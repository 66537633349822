import clsx from 'clsx';
import { IconChevronDown } from '../../../assets/icons';
import type { ReactNode } from 'react';
import type { SelectOption } from '../types';

type SelectButtonContentProps<OptionValue extends string | number> = {
  placeholder?: string;
  isOpen: boolean;
  isDisabled: boolean;
  icon: ReactNode;
  variant: 'single-select' | 'multi-select';
  options: SelectOption<OptionValue>[];
  selectedOptions?: SelectOption<OptionValue> | SelectOption<OptionValue>[];
};

export const SelectButtonContent = <OptionValue extends string | number>({
  placeholder,
  options,
  selectedOptions,
  isOpen,
  icon,
  isDisabled,
}: SelectButtonContentProps<OptionValue>) => {
  const getPlaceholder = () => {
    if (selectedOptions) {
      if (Array.isArray(selectedOptions)) {
        if (selectedOptions.length) {
          if (options.length === selectedOptions.length) {
            return 'All';
          }
          return selectedOptions.map((item) => item.label).join(', ');
        }
        return placeholder;
      } else {
        return selectedOptions.label;
      }
    }

    return placeholder;
  };

  return (
    <>
      {icon}

      <span
        className={clsx('text-sm overflow-hidden text-ellipsis whitespace-nowrap', {
          'text-grey-1': !isDisabled,
          'text-grey-4': isDisabled,
        })}
      >
        {getPlaceholder()}
      </span>

      <IconChevronDown
        className={clsx('w-4 h-4 ml-auto transition-transform', {
          'transform rotate-180': isOpen,
          'transform rotate-0': !isOpen,
        })}
      />
    </>
  );
};
