import { Select } from '../../Select';

const pageItemsCountOptions = [
  {
    value: 25,
    label: '25',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 100,
    label: '100',
  },
];

type PaginationCountPerPageProps = {
  isLoading: boolean;
  pageItemsCount: number;
  onPageItemsCountChange: (count: number) => void;
};

export const PaginationCountPerPage = ({
  isLoading,
  pageItemsCount,
  onPageItemsCountChange,
}: PaginationCountPerPageProps) => (
  <div className="flex gap-4 items-center">
    <span className="text-body-md text-grey-1">Entries per page</span>
    <Select
      isDisabled={isLoading}
      options={pageItemsCountOptions}
      selected={pageItemsCount}
      onChange={(option) => onPageItemsCountChange(option.value)}
      buttonClassName="w-full"
      variant="single-select"
    />
  </div>
);
