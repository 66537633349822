import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBase';
import { ApiResponse } from '../types';
import {
  GetDistributorByIdRequestData,
  GetDistrubuterListRequestData,
  InviteUserRequestBody,
  PostDistributorRequestBody,
  DistributorListItem,
} from './types';
import { MutationExtraType } from '../../../types';

export const distributerApi = createApi({
  reducerPath: 'distributerApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getDistrubuterList: builder.mutation<
      ApiResponse<GetDistrubuterListRequestData>,
      MutationExtraType
    >({
      query: (body) => ({
        url: 'api/getUsers',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    postdistrubutor: builder.mutation<ApiResponse<null>, PostDistributorRequestBody>({
      query: (body) => ({
        url: 'api/addUser',
        method: 'POST',
        body,
      }),
    }),
    updatedistrubutor: builder.mutation<ApiResponse<null>, { [key: string]: unknown }>({
      query: (body) => ({
        url: 'api/updateUser',
        method: 'POST',
        body,
      }),
    }),
    postdistrubutorInvite: builder.mutation<ApiResponse<null>, InviteUserRequestBody>({
      query: (body) => ({
        url: 'api/inviteUser',
        method: 'POST',
        body,
      }),
    }),
    distributorOnboarding: builder.mutation<ApiResponse<GetDistributorByIdRequestData>, string>({
      query: (token) => ({
        url: `api/verify?token=${token}`,
        method: 'GET',
      }),
    }),
    getDistributorById: builder.query<ApiResponse<GetDistributorByIdRequestData>, string>({
      query: (params) => `api/getUserById?${params}`,
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getDistributorList: builder.query<DistributorListItem[], void>({
      query: () => ({
        url: '/api/v2/distributors',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetDistrubuterListMutation,
  usePostdistrubutorMutation,
  useUpdatedistrubutorMutation,
  usePostdistrubutorInviteMutation,
  useDistributorOnboardingMutation,
  useGetDistributorByIdQuery,
  useGetDistributorListQuery,
} = distributerApi;
