import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

const environment = process.env.REACT_APP_ENVIRONMENT;

const isStaging = environment === 'staging';

const replaysSessionSampleRate = isStaging ? 0 : 0.1;
const replaysOnErrorSampleRate = isStaging ? 0 : 1.0;

const integrations = [
  Sentry.reactRouterV6BrowserTracingIntegration({
    useEffect,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
  }),
];

if (environment !== 'staging') {
  integrations.push(Sentry.replayIntegration());
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations,
  tracesSampleRate: 1.0,
  environment,
  replaysSessionSampleRate,
  replaysOnErrorSampleRate,
});
