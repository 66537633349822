import { LogLevel } from '@azure/msal-browser';

type B2CPolicyNames = {
  signUpSignIn: string;
  forgotPassword: string;
  editProfile: string;
};

type B2CAuthorities = {
  signUpSignIn: { authority: string | undefined };
  forgotPassword: { authority: string | undefined };
  editProfile: { authority: string | undefined };
};

type B2CPolicies = {
  names: B2CPolicyNames;
  authorities: B2CAuthorities;
  authorityDomain: string | undefined;
};

export const b2cPolicies: B2CPolicies = {
  names: {
    signUpSignIn: 'B2C_1_signin',
    forgotPassword: 'B2C_1_reset_v3',
    editProfile: 'B2C_1_edit_profile_v2',
  },
  authorities: {
    signUpSignIn: {
      authority: process.env.REACT_APP_AAD_AUTHORITIES_SIGNUP_SIGNIN_AND_PASSWORD,
    },
    forgotPassword: {
      authority: process.env.REACT_APP_AAD_AUTHORITIES_SIGNUP_SIGNIN_AND_PASSWORD,
    },
    editProfile: {
      authority: process.env.REACT_APP_AAD_AUTHORITIES_EDIT_PROFILE,
    },
  },
  authorityDomain: process.env.REACT_APP_AZURE_AUTHORITY_DOMAIN_URL,
};

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AAD_CLIENT_ID!,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain!],
    redirectUri: process.env.REACT_APP_AZURE_REDIRECTURI!,
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: true,
    tenantId: process.env.REACT_APP_TENANT_ID!,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: [],
};
