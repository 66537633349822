import { useState, useEffect, useCallback, useRef } from 'react';
import { DiscountResponse, Product } from '../redux/api/retailerOrderListApi/types';
import { useCart } from '../layout/cart/useCart';
import { useRetailerOrderCheckDiscountMutation } from '../redux/api/retailerOrderListApi/retailerOrderListApi';
import { getPriceValue } from '../utils/getPriceValue';
import { UpdateQuantityData } from '../layout/cart/context';

export type OrderTotals = {
  total: number;
  subtotal: number;
  discount: number;
};
export const useDiscounts = (data?: Product[], isCart?: boolean) => {
  const { selectedLocation, cart } = useCart();
  const [checkProductDiscount] = useRetailerOrderCheckDiscountMutation();
  const [initialDiscounts, setInitialDiscounts] = useState<Record<string, DiscountResponse>>({});
  const [updatedQuantityDiscountsLoading, setUpdatedQuantityDiscountsLoading] = useState<
    Record<string, boolean>
  >({});
  const [isAllDiscountsLoading, setAllDiscountsLoading] = useState(false);

  const updateTimeoutRef = useRef<Record<string, ReturnType<typeof setTimeout>>>({});

  const [orderTotals, setOrderTotals] = useState<OrderTotals>({
    subtotal: 0,
    total: 0,
    discount: 0,
  });

  useEffect(() => {
    const fetchDiscounts = async () => {
      if (data?.length) {
        setAllDiscountsLoading(true);
        const allProductsInPageDiscounts = await Promise.all(
          data.map(async (item) => {
            const quantity = selectedLocation?.id
              ? cart?.[selectedLocation.id]?.data?.find(
                  (cartItem) => cartItem.product.productId === item.productId,
                )?.selectedCount || 0
              : 0;
            const res = await checkProductDiscount({ sku: item.sku, quantity });
            return res.data?.data as DiscountResponse;
          }),
        );
        setInitialDiscounts(
          allProductsInPageDiscounts.reduce(
            (acc: Record<string, DiscountResponse>, item, index) => {
              acc[data[index].productId] = item;
              return acc;
            },
            {},
          ),
        );
        setAllDiscountsLoading(false);
      }
    };

    fetchDiscounts();
  }, [!isCart && data, selectedLocation]);

  const handleQuantityChange = useCallback(
    (updateData: UpdateQuantityData[]) => {
      updateData.forEach(({ product, updatedQuantity }) => {
        clearTimeout(updateTimeoutRef.current[product.productId]);
        const updateQuantity = async () => {
          setUpdatedQuantityDiscountsLoading((prev) => ({ ...prev, [product.productId]: true }));
          const discountData = await checkProductDiscount({
            sku: product.sku,
            quantity: updatedQuantity,
          });
          setInitialDiscounts((prev) => ({
            ...prev,
            [product.productId]: discountData.data?.data as DiscountResponse,
          }));
          setUpdatedQuantityDiscountsLoading((prev) => ({ ...prev, [product.productId]: false }));
        };
        updateTimeoutRef.current[product.productId] = setTimeout(() => {
          updateQuantity();
        }, 500);
      });
    },
    [checkProductDiscount, data],
  );

  useEffect(() => {
    if (isCart) {
      setOrderTotals(
        Object.values(initialDiscounts)
          .filter((product) => {
            const currentProductInCard = selectedLocation?.id
              ? cart?.[selectedLocation.id]?.data?.find(
                  (cartItem) => cartItem.product.sku === product.sku,
                )
              : undefined;
            return currentProductInCard && currentProductInCard.selectedCount > 0;
          })
          .reduce(
            (acc, item) => {
              acc.subtotal += getPriceValue(item.grossPrice);
              acc.total += getPriceValue(item.discountedPrice);
              acc.discount += getPriceValue(item.discountAmount);
              return acc;
            },
            { subtotal: 0, total: 0, discount: 0 },
          ),
      );
    }
  }, [initialDiscounts, cart]);

  useEffect(() => {
    return () => {
      Object.values(updateTimeoutRef.current).forEach((item) => {
        clearTimeout(item);
      });
    };
  }, []);

  return {
    initialDiscounts,
    updatedQuantityDiscountsLoading,
    isAllDiscountsLoading,
    handleQuantityChange,
    orderTotals,
  };
};
