import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBase';
import { ApiResponse } from '../types';
import {
  DiscountResponse,
  GetProductsListResponse,
  GetRetailerLocationsResponse,
  GetRetailerOrderDetailRequestData,
  GetRetailerSearchProductRequestBody,
  PlaceRetailerOrderRequestBody,
  PlaceRetailerOrderRequestData,
  RetailerCheckDiscountForMultipleProductsRequestBody,
  RetailerOrderCheckDiscountRequestBody,
  RetailerOrderData,
  RetailerOrdersListResponse,
  RetailerOrdersRequestBody,
} from './types';
import { MutationExtraType } from '../../../types';
import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

export const retailerOrderListApi = createApi({
  reducerPath: 'retailerOrderListApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getRetailerOrderList: builder.mutation<ApiResponse<null>, null>({
      query: (body) => ({
        url: 'api/getOrders',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),

    getRetailerOrderDetail: builder.mutation<
      ApiResponse<GetRetailerOrderDetailRequestData>,
      MutationExtraType
    >({
      query: (body) => ({
        url: 'api/getOrderById',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),

    getRetailerSearchProduct: builder.mutation<
      ApiResponse<RetailerOrderData>,
      GetRetailerSearchProductRequestBody
    >({
      query: (body) => ({
        url: 'api/SearchProduct',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),

    getDistributorProductsList: builder.query<GetProductsListResponse, string>({
      query: (query: string) => ({
        url: `/api/v2/products?${query}`,
        method: 'GET',
      }),
    }),

    getRetailerLocations: builder.query<GetRetailerLocationsResponse, void>({
      query: () => ({
        url: '/api/v2/locations',
        method: 'GET',
      }),
    }),

    placeRetailerOrder: builder.mutation<
      PlaceRetailerOrderRequestData,
      PlaceRetailerOrderRequestBody
    >({
      query: (body) => ({
        url: 'api/v2/orders',
        method: 'POST',
        body,
      }),
    }),

    retailerOrderCheckDiscount: builder.mutation<
      ApiResponse<DiscountResponse>,
      RetailerOrderCheckDiscountRequestBody
    >({
      query: (body) => ({
        url: 'api/checkDiscount',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),

    retailerCheckDiscountForMultipleProducts: builder.query<
      DiscountResponse[],
      RetailerCheckDiscountForMultipleProductsRequestBody
    >({
      async queryFn(
        products,
        _api,
        _extraOptions,
        baseQuery,
      ): Promise<
        QueryReturnValue<DiscountResponse[], FetchBaseQueryError, FetchBaseQueryMeta | undefined>
      > {
        try {
          const results = await Promise.all(
            products.map((product) =>
              baseQuery({
                url: 'api/checkDiscount',
                body: product,
                method: 'POST',
              }),
            ),
          );

          const hasError = results.some((result) => result.error);

          if (hasError) {
            const errorResult = results.find((result) => result.error);
            return {
              error: errorResult?.error as FetchBaseQueryError,
              meta: errorResult?.meta,
            };
          }

          const aggregatedData = results.map(
            (result) => (result.data as { data: DiscountResponse })?.data,
          );

          return {
            data: aggregatedData,
            meta: results?.[0]?.meta,
          };
        } catch (error) {
          return {
            error: {
              status: 'CUSTOM_ERROR',
              data: error instanceof Error ? error.message : 'Unknown error',
            } as FetchBaseQueryError,
          };
        }
      },
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),

    getRetailerOrders: builder.query<RetailerOrdersListResponse, RetailerOrdersRequestBody>({
      query: (body) => ({
        url: 'api/getOrders',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetRetailerOrderListMutation,
  useGetRetailerOrdersQuery,
  useGetRetailerOrderDetailMutation,
  useGetRetailerSearchProductMutation,
  usePlaceRetailerOrderMutation,
  useRetailerOrderCheckDiscountMutation,
  useGetDistributorProductsListQuery,
  useLazyGetDistributorProductsListQuery,
  useGetRetailerLocationsQuery,
  useLazyGetRetailerLocationsQuery,
  useRetailerCheckDiscountForMultipleProductsQuery,
} = retailerOrderListApi;
