import type { SelectOption } from '../types';

export const isSelectable = <OptionValue extends string | number>(
  option: SelectOption<OptionValue>,
  variant: 'single-select' | 'multi-select',
  isLastSelectable: boolean,
  selected?: OptionValue | OptionValue[],
) => {
  if (isLastSelectable) {
    return true;
  }

  if (variant === 'single-select') {
    return option?.value !== selected;
  }

  return (
    selected && Array.isArray(selected) && (selected.length !== 1 || option?.value !== selected[0])
  );
};
